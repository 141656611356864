import React, { Component } from 'react'
import './GoToTop.scss'

class GoToTop extends Component {
    state = {
        display: 'none'
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleWindowScoll, false)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleWindowScoll, false)
    }

    handleWindowScoll = () => {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            this.setState({ display: 'block' })
        }
        else {
            this.setState({ display: 'none' })
        }
    }

    gotoTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="goToTop" style={{ display: this.state.display }}>
                <img className="icon" src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/goToTop.png" onClick={this.gotoTop}/>
            </div>
        )
    }
}

export default GoToTop