import React, { Component } from "react"
import "./NavMainPC.scss"
import LanguageMenu from "../languageMenu/LanguageMenu"

class NavMainPC extends Component {
  render() {
    return (
      <div className="navMainPc space">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 navMainPcItem">
              <a className="ookbeeLogo">
                <img
                  className="image space"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/ookbee.png"
                />
              </a>
            </div>
            <div className="col navMainPcItem"></div>
            <div className="col-lg-3 justify-content-end navMainPcItem">
              <LanguageMenu />
              {/*<a className="" href={"https://www.ookbee.com/"} target="_blank">
                <img
                  className="image logoLanguage"
                  src="https://img.ookbee.com/Shop/ookbee/OokbeeNewWeb/language.png"
                />
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NavMainPC
