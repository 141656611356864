import React, { useState } from "react"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import "./LanguageMenu.scss"

const LanguageMenu = props => {
  const { t, i18n } = useTranslation()

  const [values, setValues] = useState({
    language: "en",
  })

  function handleChange(event) {
    i18n.changeLanguage(event.target.value)

    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))
  }
  const {size} = props;
  return (
    <div  className="space">
    {size != "mobile" && <Select
      value={values.language}
      onChange={e => handleChange(e)}
      disableUnderline
      inputProps={{
        name: "language",
      }}
      className="LanguageMenu"
    >
      <MenuItem value={"en"}>EN</MenuItem>
      <MenuItem value={"zh-Hant"}>TH</MenuItem>
    </Select>}
    {size == "mobile" && <Select
      value={values.language}
      onChange={e => handleChange(e)}
      disableUnderline
      inputProps={{
        name: "language",
      }}
      className="LanguageMenuMobile"
    >
      <MenuItem value={"en"}>EN</MenuItem>
      <MenuItem value={"zh-Hant"}>TH</MenuItem>
    </Select>}
    </div>
    
  )
}

export default LanguageMenu
