import React, { Component } from "react"
import "./Footer.scss"
import { withTrans } from "../../i18n/withTrans"

class Footer extends Component {
  render() {
    const { t } = this.props
    return (
      <footer className="footer" style={{ fontFamily: "Kanit" }}>
        <div className="container">
          <div className="row">
            <div className="footerMenu">
              <ul className="menuList">
                <li className="item">
                  <a className="link" href="#about">
                    <p className="text text-uppercase">{t("about.title")}</p>
                  </a>
                </li>
                <li className="item">
                  <p>|</p>
                </li>
                <li className="item">
                  <a className="link" href="#statistics">
                    <p className="text text-uppercase">
                      {t("statistics.title")}
                    </p>
                  </a>
                </li>
                <li className="item">
                  <p>|</p>
                </li>
                <li className="item">
                  <a className="link" href="#communities">
                    <p className="text text-uppercase">
                      {t("communities.title")}
                    </p>
                  </a>
                </li>
                <li className="item">
                  <p>|</p>
                </li>
                <li className="item">
                  <a className="link" href="#timeline">
                    <p className="text text-uppercase">{t("timeline.title")}</p>
                  </a>
                </li>
                <li className="item">
                  <p>|</p>
                </li>
                {/*<li className="item">
                  <a className="link" href="#news">
                    <p className="text text-uppercase">{t("news.title")}</p>
                  </a>
    </li>*/}
                <li className="item">
                  <p>|</p>
                </li>
                <li className="item">
                  <a className="link" href="#clients-partners">
                    <p className="text text-uppercase">
                      {t("clientsPartners.title")}
                    </p>
                  </a>
                </li>
                <li className="item">
                  <p>|</p>
                </li>
                <li className="item">
                  <a className="link" href="#contact">
                    <p className="text text-uppercase">{t("contact.title")}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="supportInfo">
                <p className="fontFooter">
                  © {new Date().getFullYear()} Ookbee
                </p>
              </div>
            </div>
          </div>
          {/*<div className="row justify-content-center">
            <div className="col-12">
              <div className="footerMenu">
                <ul className="menuList">
                  <li className="item">
                    <a className="link" href="#about">
                      <p className="text text-uppercase">{t("about.title")}</p>
                    </a>
                  </li>
                  <li className="item">
                    <p>|</p>
                  </li>
                  <li className="item">
                    <a className="link" href="#statistics">
                      <p className="text text-uppercase">{t("statistics.title")}</p>
                    </a>
                  </li>
                  <li className="item">
                    <p>|</p>
                  </li>
                  <li className="item">
                    <a
                      className="link"
                      href="#communities"
                    >
                      <p className="text text-uppercase">{t("communities.title")}</p>
                    </a>
                  </li>
                  <li className="item">
                    <p>|</p>
                  </li>
                  <li className="item">
                    <a className="link" href="#timeline">
                      <p className="text text-uppercase">{t("timeline.title")}</p>
                    </a>
                  </li>
                  <li className="item">
                    <p>|</p>
                  </li>
                  <li className="item">
                    <a className="link" href="#news">
                      <p className="text text-uppercase">{t("news.title")}</p>
                    </a>
                  </li>
                  <li className="item">
                    <p>|</p>
                  </li>
                  <li className="item">
                    <a
                      className="link"
                      href="#clients-partners"
                    >
                      <p className="text text-uppercase">{t("clientsPartners.title")}</p>
                    </a>
                  </li>
                  <li className="item">
                    <p>|</p>
                  </li>
                  <li className="item">
                    <a className="link" href="#contact">
                      <p className="text text-uppercase">{t("contact.title")}</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12">
              <div className="supportInfo">
                <p className="fontFooter">
                © {new Date().getFullYear()} Ookbee
                </p>
              </div>
            </div>
    </div>*/}
        </div>
      </footer>
    )
  }
}
export default withTrans(Footer)
